import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// icons
// @mui
import Image from "next/image";
import Iconify from './iconify';
import { getSocial } from '../../theme/constants';


// ----------------------------------------------------------------------

const SocialIcon = forwardRef(({ alias, width = 20, sx, ...other }, ref) => {
    const social = getSocial(alias);
    if (!social) {
      return null;
    }
    if(social.value === 'snapchat') {
      return  <Image
          src="/assets/images/snapchat-logo.svg"
          alt="Description of the SVG"
          width={width}
          height={width}
      />
    }
  if(social.value === 'microsoft-ads') {
    return <Iconify icon='logos:microsoft-icon' width={width}/>
  }
    return <Iconify icon={social.icon} sx={{ color: social.color, ...sx }} width={width} />;
  })
;

SocialIcon.propTypes = {
  alias: PropTypes.string,
  sx: PropTypes.object,
  width: PropTypes.number,
};

export default SocialIcon;
